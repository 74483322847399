<template>
  <div class="w-full mt-6 border rounded-2xl py-4 px-6 shadow-md">
    <div class="font-bold text-base">REMARK HISTORY</div>
    <div class="py-4">
      <el-input
        type="textarea"
        v-model="remarkContent"
        :rows="4"
        readonly
        class="w-full"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  remark: String,
});

const remarkContent = computed(() =>
  props.remark ? props.remark : "No comment yet"
);
</script>

<style scoped></style>
